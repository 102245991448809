const sortData = (event, sortOrder, filteredDevices, devices) => {
  let tableDevices
  if (filteredDevices.length > 0) {
    tableDevices = [...filteredDevices]
  } else {
    tableDevices = [...devices]
  }
  let columnName

  if (
    event.target.id === 'device-type-sort' ||
    event.target.parentElement.id === 'device-type-sort'
  ) {
    columnName = 'model'
  }
  if (
    event.target.id === 'friendly-name-sort' ||
    event.target.parentElement.id === 'friendly-name-sort'
  ) {
    columnName = 'name'
  }
  if (
    event.target.id === 'serial-number-sort' ||
    event.target.parentElement.id === 'serial-number-sort'
  ) {
    columnName = 'serialNumber'
  }

  const direction = sortOrder === 'asc' ? -1 : 1

  const sortedDeviceColumn = [...tableDevices].sort((a, b) =>
    a[columnName]?.toUpperCase() <
      b[columnName]?.toUpperCase()
      ? direction
      : a[columnName]?.toUpperCase() >
        b[columnName]?.toUpperCase()
        ? -direction
        : 0,
  )

  const sortedLastSeenData = [...tableDevices].sort((a, b) =>
    a.lastSeen < b.lastSeen
      ? direction
      : a.lastSeen > b.lastSeen
        ? -direction
        : 0,
  )

  if (
    event.target.id === 'last-seen-sort' ||
    event.target.parentElement.id === 'last-seen-sort'
  ) {
    return sortedLastSeenData
  }
  return sortedDeviceColumn
}

export default sortData
