import { Link } from "react-router-dom"


const ChangeStore = () => {
    return (     
     <><Link to="/stores" >
            Change store
        </Link></>
    )
    }
  
  export default ChangeStore
  