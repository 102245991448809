import PropTypes from 'prop-types'
import getRelativeDate from '../../../utils/relativeDate'

function DevicesTableRow({ device }) {
  const { onlineStatus } = device
  return (
    <>
      <tr
        className={
          !onlineStatus
            ? 'ln-c-table__row dm__offlineDevice'
            : 'ln-c-table__row dm__onlineDevice'
        }
        key={device.deviceId}
        id={`device-row-${device.deviceId}`}
      >
        <td className="ln-c-table__cell dm__deviceModel" id="device-model">
          {device.group.includes('SmartShop')
            ? device.group
            : device.model}
        </td>
        <td
          className="ln-c-table__cell dm__deviceFriendlyName"
          id="device-friendly-name"
        >
          {device.name}
        </td>
        <td
          className="ln-c-table__cell dm__deviceSerialNumber"
          id="device-serial-number"
        >
          <span className="dm__mobileTag" id="mobile-tag">
            Serial Number &nbsp;
          </span>
          {device.serialNumber}
        </td>
        <td
          className="ln-c-table__cell dm__deviceLastCheckIn"
          id="device-last-check-in"
        >
          <span className="dm__mobileTag" id="mobile-tag">
            Last Checked In &nbsp;
          </span>
          <span className={!onlineStatus ? 'red-text' : ''}>
            {getRelativeDate(device.lastSeen)}
          </span>
        </td>
      </tr>
    </>
  )
}

DevicesTableRow.propTypes = {
  device: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    lastSeen: PropTypes.string.isRequired,
    onlineStatus: PropTypes.bool.isRequired,
    group: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
  }).isRequired,
}

export default DevicesTableRow
