import { Sainsburys } from '@jsluna/images'

const SainsburysLogo = (): JSX.Element => (
  <Sainsburys
    aria-label="Sainsbury's logo"
    style={{ verticalAlign: 'middle', height: '16px' }}
  />
)

export default SainsburysLogo
