import PropTypes from 'prop-types'

function getGroupedDevices(array) {
  const name = device => device.model

  return array.reduce(
    (devices, device) => ({
      ...devices,
      [name(device)]: [...(devices[name(device)] || []), device],
    }),
    {},
  )
}

function DeviceCounter({ devices }) {
  const deviceGroup = getGroupedDevices(devices)

  return (
    <div className="dm__deviceCounter ln-u-padding-left*3" id="device-counter">
      {Object.keys(deviceGroup).map(simpleModel => {
        const device = deviceGroup[simpleModel]
        return (
          <span className="dm__deviceName" key={simpleModel}>
            &nbsp;{simpleModel}: &nbsp;{device.length}&nbsp;
            {device.filter(d => d.onlineStatus === false).length > 0 ? (
              <>
                (
                <span className="red__text" id="red-text">
                  {device.filter(d => d.onlineStatus === false).length}
                  &nbsp;Offline
                </span>
                )
              </>
            ) : (
              ''
            )}
          </span>
        )
      })}
    </div>
  )
}

DeviceCounter.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      onlineStatus: PropTypes.bool,
    }),
  ).isRequired,
}

export default DeviceCounter
